import React, { Fragment } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { pink } from '@material-ui/core/colors';
import 'typeface-open-sans';
import Home from './Home';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#0b9cda',
      main: '#0b9cda',
      dark: '#0a7bbb',
      contrastText: '#ffffff',
    },
    secondary: pink,
    action: {
      selected: 'rgba(11, 156, 218, 0.1)',
      hover: 'rgba(0, 0, 0, 0)',
    },
    background: {
      baseWhite: '#ffffff',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Fragment>
      <CssBaseline />
      <Home />
    </Fragment>
  </MuiThemeProvider>
);

export default App;
