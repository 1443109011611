import {
  FETCH_APP_LIST_HAS_UPDATES_STARTED,
  FETCH_APP_LIST_HAS_UPDATES_SUCCESS,
  FETCH_APP_LIST_HAS_UPDATES_FAILURE,
  SET_UPDATE_STATE,
} from './actionTypes';
import api from '../api';

export const fetchAppListHasUpdatesStarted = timestamp => ({
  type: FETCH_APP_LIST_HAS_UPDATES_STARTED,
  timestamp,
});

export const fetchAppListHasUpdatesSuccess = (timestamp, data) => ({
  type: FETCH_APP_LIST_HAS_UPDATES_SUCCESS,
  timestamp,
  payload: {
    data,
  },
});

export const fetchAppListHasUpdatesFailure = err => ({
  type: FETCH_APP_LIST_HAS_UPDATES_FAILURE,
  payload: {
    err,
  },
});

export const fetchAppListHasUpdates = apps => (
  (dispatch) => {
    const timestamp = Date.now();
    dispatch(fetchAppListHasUpdatesStarted(timestamp));
    return api.appListHasUpdates(apps).then((res) => {
      dispatch(fetchAppListHasUpdatesSuccess(timestamp, res));
    }).catch((err) => {
      dispatch(fetchAppListHasUpdatesFailure(err));
    });
  }
);

export const setUpdateState = (appId, updateState) => ({
  type: SET_UPDATE_STATE,
  payload: {
    appId,
    updateState,
  },
});
