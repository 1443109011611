let origin;
if (!window.location.host.includes('staging.')) {
  origin = 'hidnajblbifdkmheebalalchohohmaef';
} else {
  origin = 'dcpmfcngljljmfebilibmlfaokeginmb';
}
const config = {
  systemId: 'mofiofjpikncjaigmdlblhojbnkabako',
  commands: {
    appList: 'get_app_list',
    appListDetail: 'get_app_list_detail',
    arcListVersion: 'get_arc_list_version',
    installCrx: 'install',
    startApp: 'start_app',
    forceStartApp: 'force_start_app',
    uninstallApp: 'uninstall',
    echo: 'echo',
    fetchChromeVersion: 'fetch_chrome_version',
    fetchProgress: 'fetch_progress',
    openUrl: 'open_url',
    getArcSignedState: 'get_arc_signed_state',
    getFydeosArcStatus: 'get_fydeos_arc_status',
    getBoardName: 'get_board_name',
    stopInstallAndroid: 'stop_install_android',
    getFydeosInfo: 'get_fydeos_info',
  },
  state: {
    SUCCESS: 'OK',
    ERROR: 'ERROR',
    PROGRESS: 'PROGRESS',
  },
  dir: {
    FROM: 'from_page',
    TO: 'to_page',
  },
  storeOrigin: `chrome-extension://${origin}`,
};

export default config;
