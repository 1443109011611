import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { addLocaleData, IntlProvider } from 'react-intl';
import zhLocalData from 'react-intl/locale-data/zh';
import * as Sentry from '@sentry/browser';
import qs from 'qs';
import store from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Message from './Message';
import i18nMessages from './i18nMessage';
import './assets/global.css';

Message.initConnect(window);

window.Message = Message;

addLocaleData(zhLocalData);

// let lang = '';
// const query = qs.parse(window.location.search.replace('?', ''));
// if (query && query.hl != null && query.hl !== '') {
//   lang = query.hl;
// } else {
//   lang = navigator.language;
// }

// if (lang.startsWith('zh')) {
//   lang = 'zh';
// } else {
//   lang = 'en';
// }

const lang = location.host.includes("fydeos.io") ? "en" : "zh";

// Sentry.init({
//   dsn: 'https://e3894101863b46c09e8a24d748c40378@sentry.io/1519757',
//   environment: process.env.NODE_ENV,
// });

ReactDOM.render(
  <IntlProvider
    locale={lang}
    messages={i18nMessages[lang]}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// eslint-disable-next-line no-console
console.log(`%c Store %c ${process.env.REACT_APP_VERSION} `, 'background:#0b9cda;color:#eee', 'background:#333;color:#eee');
