import React, { useState } from 'react';
import './style.css'; // 导入你的CSS样式文件
import classNames from 'classnames';

const Carousel = ({ dataSources, onAppSelected }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevIndex = (currentIndex + dataSources.length - 1) % dataSources.length;
  const nextIndex = (currentIndex + 1) % dataSources.length;
  const [showBtn, setShowBtn] = useState(false);
  const imgRef = [];

  const next = () => {
    onChangeCurrentIndex(() => setCurrentIndex(nextIndex));
  };

  const prev = () => {
    onChangeCurrentIndex(() => setCurrentIndex(prevIndex));
  };

  const onChangeCurrentIndex = (cb) => {
    if (imgRef.length) {
      imgRef.forEach(item => {
        const timer = setInterval(() => {
          if (Number(item.style.opacity) <= 0.5) {
            clearInterval(timer);
            cb && cb();
            const timer2 = setInterval(() => {
              if (Number(item.style.opacity) >= 1) {
                clearInterval(timer2);
              }
              item.style.opacity = Number(item.style.opacity) + 0.1;
            }, 20);
          }
          item.style.opacity = Number(item.style.opacity) - 0.1;
        }, 20);
      })
    }
  }

  return (
    <div className="carousel" onMouseEnter={() => setShowBtn(true)} onMouseLeave={() => setShowBtn(false)}>
      <div>
        <svg className={classNames("carousel__button", "carousel__button--left", showBtn ? "show" : "")} onClick={prev} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="34" width="34" height="34" rx="17" transform="rotate(-90 0 34)" fill="black" fillOpacity="0.1" />
          <path d="M19 21L14.5 16.5L19 12" stroke="white" />
        </svg>
      </div>
      <div className='images'>
        <div
          className="carousel__image carousel__image--prev"
          style={{ backgroundImage: `url(${dataSources[prevIndex].image})`, opacity: "1" }}
          ref={ref => imgRef.push(ref)}
        />
        <div
          className="carousel__image carousel__image--current"
          style={{ backgroundImage: `url(${dataSources[currentIndex].image})`, opacity: "1" }}
          onClick={() => {
            onAppSelected(dataSources[currentIndex].appId)
          }}
          ref={ref => imgRef.push(ref)}
        />
        <div
          className="carousel__image carousel__image--next"
          style={{ backgroundImage: `url(${dataSources[nextIndex].image})`, opacity: "1" }}
          ref={ref => imgRef.push(ref)}
        />
      </div>
      <div>
        <svg className={classNames("carousel__button", "carousel__button--right", showBtn ? "show" : "")} onClick={next} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="34" width="34" height="34" rx="17" transform="rotate(-90 0 34)" fill="black" fillOpacity="0.1" />
          <path d="M15.2427 21L19.4853 16.7574L15.2427 12.5147" stroke="white" />
        </svg>
      </div>
    </div>
  );
};

export default Carousel;
