import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducer';

const middlewares = [thunkMiddleware];

const storeEnhancers = compose(
  applyMiddleware(...middlewares),
  f => f,
);

// const local = localStorage.getItem('redux');
// const persistedState = local ? JSON.parse(local) : {};
// const store = createStore(reducer, persistedState, storeEnhancers);
const store = createStore(reducer, {}, storeEnhancers);

// store.subscribe(() => {
//   localStorage.setItem('redux', JSON.stringify(store.getState()));
// });

export default store;
