import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Collapse
} from "@material-ui/core";
import classNames from "classnames";
import i18n from "../i18n";
import { injectIntl } from "react-intl";

const TypeList = props => {
  const {
    listItem,
    onClickType,
    classes,
    typeIconMap,
    intl,
    category,
    activeIds
  } = props;
  const [openChild, setOpenChild] = useState(false);
  const extendInfo = JSON.parse(listItem.extendInfo);
  return (
    <>
      <ListItem
        button
        key={listItem.id}
        className={classNames(classes.nested, classes.listItem)}
        selected={activeIds[activeIds[1] === 0 ? 0 : 1] === listItem.id}
        onClick={() => {
          onClickType(listItem);
          if (listItem.children && listItem.children.length > 0) {
            setOpenChild(!openChild);
          }
        }}
      >
        <ListItemText
          disableTypography
          primary={
            <Typography
              variant="subtitle1"
              className={classNames([
                classes.listItemLine,
                activeIds[activeIds[1] === 0 ? 0 : 1] === listItem.id
                  ? classes.selectedType
                  : ""
              ])}
            >
              {listItem.extendInfo &&
                React.cloneElement(typeIconMap[extendInfo.type], {
                  style: {
                    marginRight: "12px"
                  },
                  className:
                    activeIds[activeIds[1] === 0 ? 0 : 1] === listItem.id
                      ? classes.activeIcon
                      : undefined
                })}
              {listItem.name}
            </Typography>
          }
        />
        {listItem.children &&
          listItem.children.length > 0 &&
          (openChild ? (
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 7L13 7L9 11L5 7Z"
                fill={
                  activeIds[activeIds[1] === 0 ? 0 : 1] === listItem.id
                    ? "#0B9CDA"
                    : "#666666"
                }
              />
            </svg>
          ) : (
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 13L7 5L11 9L7 13Z"
                fill={
                  activeIds[activeIds[1] === 0 ? 0 : 1] === listItem.id
                    ? "#0B9CDA"
                    : "#666666"
                }
              />
            </svg>
          ))}
      </ListItem>
      {listItem.children && listItem.children.length > 0 && (
        <Collapse timeout="auto" unmountOnExit in={openChild}>
          <List>
            {listItem.children.map(childItem => (
              <ListItem
                button
                key={childItem.id}
                className={classNames(
                  classes.nested,
                  classes.categorieListItem,
                  classes.categorieChildListItem
                )}
                selected={activeIds[0] === childItem.id}
                onClick={() => {
                  onClickType(childItem);
                }}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      className={classNames(
                        activeIds[0] === childItem.id &&
                          classes.categorieSelected
                      )}
                    >
                      {childItem.name}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default injectIntl(TypeList);
