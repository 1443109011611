import { combineReducers } from 'redux';
import {
  FETCH_APP_TYPES_STARTED, FETCH_APP_TYPES_SUCCESS, FETCH_APP_TYPES_FAILURE,
  FETCH_APP_CATEGORIES_STARTED, FETCH_APP_CATEGORIES_SUCCESS, FETCH_APP_CATEGORIES_FAILURE,
  FETCH_APP_SORTBY_STARTED, FETCH_APP_SORTBY_SUCCESS, FETCH_APP_SORTBY_FAILURE,
  FETCH_APP_ORDERS_STARTED, FETCH_APP_ORDERS_SUCCESS, FETCH_APP_ORDERS_FAILURE,
  APP_FILTER_CHANGE,
} from './actionTypes';
import { TYPE_BYFYDEOS } from './constants';

const initTypesState = { data: [], loading: false };
const initCategoriesState = { data: [], loading: false };
const initSortByState = { data: [], loading: false };
const initOrdersState = { data: [], loading: false };

const typesReducer = (state = initTypesState, action) => {
  switch (action.type) {
    case FETCH_APP_TYPES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_APP_TYPES_SUCCESS: {
      const { data } = action.payload;
      const types = Object.keys(data).map(key => ({
        title: key,
        value: data[key],
      }));
      return {
        ...state,
        loading: false,
        data: types,
      };
    }
    case FETCH_APP_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

const categoriesReducer = (state = initCategoriesState, action) => {
  switch (action.type) {
    case FETCH_APP_CATEGORIES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_APP_CATEGORIES_SUCCESS: {
      const { data } = action.payload;
      const categories = data.categories.filter(x=>x);
      return {
        ...state,
        loading: false,
        data: categories,
      };
    }
    case FETCH_APP_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

const sortByReducer = (state = initSortByState, action) => {
  switch (action.type) {
    case FETCH_APP_SORTBY_STARTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_APP_SORTBY_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        data,
      };
    }
    case FETCH_APP_SORTBY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

const ordersReducer = (state = initOrdersState, action) => {
  switch (action.type) {
    case FETCH_APP_ORDERS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_APP_ORDERS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        data,
      };
    }
    case FETCH_APP_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

const initFilterState = {
  categoryType: TYPE_BYFYDEOS,
};

const filterReducer = (state = initFilterState, action) => {
  switch (action.type) {
    case APP_FILTER_CHANGE: {
      const { filter } = action.payload;
      return {
        ...filter,
      };
    }
    default:
      return state;
  }
};

const reducer = combineReducers({
  types: typesReducer,
  categories: categoriesReducer,
  sortBy: sortByReducer,
  orders: ordersReducer,
  filter: filterReducer,
});

export default reducer;
