import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import { view as Sidebar } from "../Sidebar";
import { view as Main } from "../Main";
import i18n from "../i18n";
import ScrollTop from "../ScrollTop";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.baseWhite,
    minWidth: "960px",
    overflowX: "auto",
    maxWidth: "1280px",
    margin: "0 auto",
    "@media (prefers-color-scheme: dark)": {
      backgroundColor: "rgba(32, 33, 36, 1)!important"
    }
  },
  appBar: {
    color: grey[900],
    boxShadow: "none",
    maxWidth: "1280px",
    margin: "0 auto",
    right: "unset!important",
    "@media (prefers-color-scheme: dark)": {
      backgroundColor: "rgba(32, 33, 36, 1)!important"
    }
  },
  logo: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 2
  },
  grow: {
    flex: 1,
    "@media (max-width: 549px)": {
      display: "none"
    },
    fontSize: "18px",
    "@media (prefers-color-scheme: dark)": {
      color: "#E8EAED"
    }
  },
  item: {
    minHeight: `calc(100vh - ${theme.spacing.unit * 8}px)`
  },
  sidebar: {
    width: "280px",
    paddingLeft: "12px",
    position: "fixed",
    overflowY: "scroll",
    maxHeight: "80vh",
    paddingTop: "27px",
    zIndex: "1299",
    "@media (prefers-color-scheme: dark)": {
      backgroundColor: "rgba(32, 33, 36, 1)"
    },
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  main: {
    backgroundColor: theme.palette.background.baseWhite,
    width: "calc(100% - 280px)",
    marginLeft: "280px",
    marginTop: "-4px",
    "@media (prefers-color-scheme: dark)": {
      backgroundColor: "rgba(32, 33, 36, 1)"
    },
    paddingTop: "27px",
    minWidth: "720px"
  },
  grid: {
    marginTop: "64px"
  }
});

const Home = props => {
  const { classes, intl } = props;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="inherit" className={classes.appBar}>
        <Toolbar>
          <img
            src="/images/logo.svg"
            alt={i18n(intl, "appName")}
            className={classes.logo}
          />
          <Typography variant="h6" color="inherit" className={classes.grow}>
            {location.href.includes(".stag")
              ? "Staging Store"
              : i18n(intl, "appName")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container className={classes.grid}>
        <div className={classNames(classes.item, classes.sidebar)}>
          <Sidebar />
        </div>
        <div className={classNames(classes.item, classes.main)}>
          {props.categories.length > 0 && <Main />}
        </div>
      </Grid>
      <ScrollTop />
    </div>
  );
};

Home.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    appBar: PropTypes.string,
    item: PropTypes.string,
    sidebar: PropTypes.string,
    main: PropTypes.string,
    logo: PropTypes.string
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => {
  const { filter, categories } = state.basic;
  return {
    filter,
    categories: categories.data
  };
};

export default connect(mapStateToProps)(injectIntl(withStyles(styles)(Home)));
