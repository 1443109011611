import React, { Component } from "react";
import {
  List,
  ListItem,
  ListItemText,
  FormControl,
  FormLabel,
  MenuItem,
  IconButton,
  Select,
  InputLabel,
  Typography,
  InputAdornment,
  TextField,
  Collapse
} from "@material-ui/core";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import {
  fetchAppTypes,
  fetchAppCategories,
  handleFilterChange
} from "./actions";
import {
  TYPE_NULL,
  TYPE_APPS,
  TYPE_EXTENSIONS,
  TYPE_BYFYDEOS,
  TYPE_ESSENTIALS,
  TYPE_ANDROID,
  TYPE_THEMES
} from "./constants";
import { fetchAppList } from "../Main/actions";
import i18n from "../i18n";
import TypeList from "./typeList";

import { ReactComponent as AndroidApps } from "../assets/androidApps.svg";
import { ReactComponent as Essentials } from "../assets/essentials.svg";
import { ReactComponent as MadeByFydeOS } from "../assets/madeByFydeOS.svg";
import { ReactComponent as Themes } from "../assets/themes.svg";
import { ReactComponent as WebApps } from "../assets/webApps.svg";
import { ReactComponent as Extensions } from "../assets/extensions.svg";
import { ReactComponent as TestApps } from "../assets/testApps.svg";

const typeIconMap = {
  99: <TestApps />,
};

typeIconMap[Number(TYPE_APPS)] = <WebApps />;
typeIconMap[Number(TYPE_EXTENSIONS)] = <Extensions />;
typeIconMap[Number(TYPE_BYFYDEOS)] = <MadeByFydeOS />;
typeIconMap[Number(TYPE_ESSENTIALS)] = <Essentials />;
typeIconMap[Number(TYPE_ANDROID)] = <AndroidApps />;
typeIconMap[Number(TYPE_THEMES)] = <Themes />;

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing.unit * 3
  },
  nav: {
    paddingTop: "0px!important",
    paddingBottom: "0px!important"
  },
  selectedType: {
    color: "#0b9cda",
    borderRadius: "45px",
    fontWeight: 600,
    "@media (prefers-color-scheme: dark)": {
      color: "#0B9CDA!important"
    }
  },
  listItem: {
    borderRadius: "45px",
    width: "236px",
    padding: "10px 18px!important",
    transition: "all .2s ease-in-out",
    marginBottom: "8px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)"
    }
  },
  formControl: {
    width: "80%",
    margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`
  },
  orderBy: {
    display: "flex",
    alignItems: "center",
    margin: `0 ${theme.spacing.unit * 3}px`
  },
  orderByLabel: {
    marginRight: theme.spacing.unit * 2
  },
  selectedOrder: {
    backgroundColor: theme.palette.primary.main,
    color: grey[100],
    "&:hover": {
      opacity: 0.7,
      backgroundColor: theme.palette.primary.light
    }
  },
  button: {
    margin: theme.spacing.unit
  },
  formControlLabel: {
    marginRight: theme.spacing.unit * 3
  },
  label: {
    fontSize: theme.typography.subtitle2.fontSize
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row"
  },
  hidden: {
    display: "none"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: "100%"
  },
  searchIcon: {
    "@media (prefers-color-scheme: dark)": {
      color: "rgba(232, 234, 237, 1)!important"
    }
  },
  resetButton: {
    width: "100%"
  },
  dividerLine: {
    padding: "0 10px"
  },
  categorieListItem: {
    backgroundColor: "transparent!important"
  },
  categorieChildListItem: {
    paddingLeft: "58px",
    "& p": {
      "@media (prefers-color-scheme: dark)": {
        color: "#E8EAED"
      }
    }
  },
  categorieSelected: {
    color: "#0b9cda",
    "@media (prefers-color-scheme: dark)": {
      color: "#0b9cda!important"
    }
  },
  searchInputBox: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1101,
    width: "100vw",
    height: "64px",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      color: "#8C8C8C"
    },
    "@media (max-width: 1079px)": {
      alignItems: "flex-end",
      paddingRight: "24px"
    },
    "@media (max-width: 599px)": {
      height: "56px",
      paddingRight: "16px"
    }
  },
  searchInput: {
    height: "40px !important",
    width: "400px",
    transition: "width .2s ease-in",
    background: "#F1F3F4",
    borderRadius: "100px",
    fontSize: "14px",
    "& fieldset": {
      borderRadius: "100px",
      borderColor: "#F1F3F4 !important",
      "@media (prefers-color-scheme: dark)": {
        borderColor: "rgba(55, 56, 58, 1)!important"
      }
    },
    "@media (max-width: 679px)": {
      width: "300px"
    },
    "@media (max-width: 549px)": {
      width: "38px",
      paddingLeft: "8px",
      "&.active": {
        width: "280px",
        paddingLeft: "14px"
      }
    },
    "& svg": {
      "@media (prefers-color-scheme: dark)": {
        color: "rgba(232, 234, 237, 1)!important"
      }
    },
    "@media (prefers-color-scheme: dark)": {
      background: "rgba(55, 56, 58, 1)!important"
    },
    "& input": {
      "@media (prefers-color-scheme: dark)": {
        color: "rgba(232, 234, 237, 1)!important"
      }
    }
  },
  listItemLine: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px!important",
    "@media (prefers-color-scheme: dark)": {
      color: "#E8EAED"
    }
  },
  activeIcon: {
    filter: "drop-shadow(#0b9cda 1280px 0)",
    transform: "translateX(-1280px)"
  },
  clearSearchInput: {
    cursor: "pointer"
  }
});

const itemToIcon = item => {
  switch (item) {
    case "desc":
      return <ArrowDownward />;
    case "asc":
      return <ArrowUpward />;
    default:
      return null;
  }
};

class Sidebar extends Component {
  constructor(...args) {
    super(...args);
    this.handleSortByChange = this.handleSortByChange.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleTypeClick = this.handleTypeClick.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleResetButton = this.handleResetButton.bind(this);
    this.handleUrlParamsInitType = this.handleUrlParamsInitType.bind(this);
    this.actionChangeWidth = this.actionChangeWidth.bind(this);
    this.actionHideSearchBox = this.actionHideSearchBox.bind(this);
    this.state = {
      width: window.innerWidth,
      inputBoxActived: false,
      testingMode: localStorage.getItem("testingMode") === "true",
      openExtension: false,
      searchValue: "",
      activeIds: [0, 0]
    };
  }

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
    this.handleUrlParamsInitType();
    window.addEventListener("resize", this.actionChangeWidth);
    window.addEventListener("click", this.actionHideSearchBox);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(!prevState.activeIds[0] && !prevState.activeIds[1] && !prevState.searchValue && nextProps.categories && nextProps.categories.length > 0) {
      return {
        activeIds: [nextProps.categories[0].id, 0]
      }
    }

    return null;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.actionChangeWidth);
    window.removeEventListener("click", this.actionHideSearchBox);
  }

  actionChangeWidth() {
    this.setState({
      width: window.innerWidth,
      inputBoxActived: !(window.innerWidth <= 549)
    });
  }

  actionHideSearchBox(e) {
    const { width } = this.state;
    if (width <= 549 && e.y > 64) {
      this.setState({
        inputBoxActived: false
      });
    }
  }

  actionWrapFilter(filter) {
    const {
      intl: { locale }
    } = this.props;
    return { ...filter, };
  }

  handleSortByChange(e) {
    const { onFilterChange, filter } = this.props;
    onFilterChange(
      this.actionWrapFilter({ ...filter, sortBy: e.target.value })
    );
  }

  handleOrderChange(order) {
    const { onFilterChange, filter } = this.props;
    onFilterChange(this.actionWrapFilter({ ...filter, order }));
  }

  handleTypeClick(item) {
    const { onFilterChange, filter } = this.props;

    const page = 1;
    this.setState(
      {
        activeIds: [item.id, item.parentId || 0],
        searchValue: ""
      },
      () => {
        onFilterChange(
          this.actionWrapFilter({
            ...filter,
            categoryType: item && item.extendInfo ? JSON.parse(item.extendInfo).type : 3,
            category: item.id,
            keywords: ""
          }),
          page
        );
      }
    );
  }

  handleCategoryChange(category) {
    const { onFilterChange, filter } = this.props;
    onFilterChange(
      this.actionWrapFilter({
        ...filter,
        type: TYPE_EXTENSIONS,
        category,
        keywords: null
      })
    );
  }

  handleSearchChange(text) {
    if (text === "open test") {
      localStorage.setItem("testingMode", "true");
      this.setState({
        testingMode: true
      });
    }
    if (text === "close test") {
      localStorage.removeItem("testingMode");
      this.setState({
        testingMode: false
      });
    }
    if (text === "switch-to-stage-env-fydeosyoucanyouup") {
      location.href = "https://store.staging.fydeos.com";
      return;
    }
    if (text === "switch-to-prod-env-fydeosyoucanyouup") {
      location.href = "https://store.fydeos.com";
      return;
    }
    if (text === "") {
      this.handleResetButton();
      return;
    }
    const { onFilterChange, filter } = this.props;
    this.setState(
      {
        activeIds: [0, 0]
      },
      () => {
        onFilterChange(
          this.actionWrapFilter({
            ...filter,
            keywords: text
          })
        );
      }
    );
  }

  handleResetButton() {
    const { onFilterChange, filter } = this.props;
    onFilterChange(
      this.actionWrapFilter({
        ...filter,
        name: null,
        type: TYPE_BYFYDEOS,
        category: 22
      })
    );
  }

  handleUrlParamsInitType() {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("init")) return;
    const initType = urlParams.get("init");
    switch (initType) {
      case "apps":
        this.handleTypeClick(TYPE_APPS);
        break;
      case "extensions":
        this.handleTypeClick(TYPE_EXTENSIONS);
        break;
      case "fydeos":
        this.handleTypeClick(TYPE_BYFYDEOS);
        break;
      case "essentials":
        this.handleTypeClick(TYPE_ESSENTIALS);
        break;
      case "android":
        this.handleTypeClick(TYPE_ANDROID);
        break;
      case "themes":
        this.handleTypeClick(TYPE_THEMES);
        break;
      default:
        break;
    }
  }

  render() {
    const {
      classes,
      types,
      categories,
      sortByList,
      orders,
      filter,
      intl
    } = this.props;
    const { category = 22 } = filter;
    const {
      width,
      inputBoxActived,
      testingMode,
      openExtension,
      searchValue,
      activeIds
    } = this.state;

    return (
      <div>
        <List component="nav" className={classes.nav}>
          {categories
            .filter(x => testingMode || x.id !== 99)
            .map(item => (
              <React.Fragment key={item.id}>
                <TypeList
                  listItem={item}
                  onClickType={this.handleTypeClick}
                  classes={classes}
                  typeIconMap={typeIconMap}
                  category={category}
                  activeIds={activeIds}
                />
              </React.Fragment>
            ))}
        </List>
        <FormControl className={classNames(classes.formControl)}>
          <div className={classes.search}>
            <TextField
              className={classes.searchInputBox}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      className={styles.searchIcon}
                      style={{
                        width: "18px",
                        height: "18px",
                        color: "rgba(140, 140, 140, 1)"
                      }}
                    />
                  </InputAdornment>
                ),
                className: classNames(
                  classes.searchInput,
                  inputBoxActived && "active"
                ),
                placeholder: i18n(intl, "search"),
                onClick:
                  width <= 549
                    ? () => {
                        this.setState({
                          inputBoxActived: true
                        });
                      }
                    : undefined,
                autoFocus: inputBoxActived,
                endAdornment: searchValue && (
                  <svg
                    className={classes.clearSearchInput}
                    onClick={() => this.setState({ searchValue: "" })}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_2155_7289)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM12.1997 5.00006L12.9997 5.80004L9.79985 8.99986L12.9998 12.1998L12.1998 12.9997L8.99988 9.79984L5.79991 12.9998L4.99993 12.1998L8.1999 8.99986L5.00001 5.79997L5.79998 4.99999L8.99988 8.19989L12.1997 5.00006Z"
                        fill="#999999"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2155_7289">
                        <rect width="18" height="18" rx="9" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )
              }}
              variant="outlined"
              onKeyPress={e => {
                if (e.key === "Enter") {
                  this.handleSearchChange(searchValue);
                }
              }}
              value={searchValue}
              onChange={e => this.setState({ searchValue: e.target.value })}
            />
          </div>
        </FormControl>
        <div
          component="fieldset"
          className={classNames(
            classes.formControl,
            classes.orderBy,
            classes.hidden
          )}
        >
          <FormLabel
            component="legend"
            className={classNames(classes.label, classes.orderByLabel)}
          >
            {i18n(intl, "orderBy")}
          </FormLabel>
          {orders.map(item => (
            <IconButton
              key={item}
              className={classNames(
                classes.button,
                order === item ? classes.selectedOrder : ""
              )}
              aria-label={item}
              onClick={() => this.handleOrderChange(item)}
            >
              {itemToIcon(item)}
            </IconButton>
          ))}
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.shape({
    nested: PropTypes.string,
    formControl: PropTypes.string,
    label: PropTypes.string,
    radioGroup: PropTypes.string,
    formControlLabel: PropTypes.string,
    listItem: PropTypes.string
  }).isRequired,
  onLoad: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object),
  sortByList: PropTypes.arrayOf(PropTypes.string),
  orders: PropTypes.arrayOf(PropTypes.string),
  filter: PropTypes.shape({
    type: PropTypes.number,
    category: PropTypes.string,
    sortBy: PropTypes.string,
    order: PropTypes.string
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

Sidebar.defaultProps = {
  types: [],
  categories: [],
  sortByList: [],
  orders: [],
  filter: {
  }
};

const mapStateToProps = state => {
  const { types, categories, sortBy, orders, filter } = state.basic;
  return {
    types: types.data,
    categories: categories.data,
    sortByList: sortBy.data,
    orders: orders.data,
    filter: {
    }
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(fetchAppCategories());
  },
  onFilterChange: (filter, page) => {
    dispatch(handleFilterChange(filter));
    dispatch(fetchAppList(filter, page));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(Sidebar)));
