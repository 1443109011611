import {
  FETCH_APP_LIST_STARTED, FETCH_APP_LIST_SUCCESS, FETCH_APP_LIST_FAILURE,
  FETCH_APP_DETAIL_STARTED, FETCH_APP_DETAIL_SUCCESS, FETCH_APP_DETAIL_FAILURE,
  APP_SELECTED, SET_APP_STATE,
  FETCH_INSTALL_PROGRESS,
} from './actionTypes';
import api from '../api';
import Message from '../Message';

export const fetchAppListStarted = (timestamp, page) => ({
  type: FETCH_APP_LIST_STARTED,
  timestamp,
  page,
});

export const fetchAppListSuccess = (timestamp, data) => ({
  type: FETCH_APP_LIST_SUCCESS,
  timestamp,
  payload: {
    data,
  },
});

export const fetchAppListFailure = err => ({
  type: FETCH_APP_LIST_FAILURE,
  payload: {
    err,
  },
});

export const fetchAppList = (filter, page) => (
  (dispatch) => {
    const timestamp = Date.now();
    dispatch(fetchAppListStarted(timestamp, page));
    return api.appList(filter, page).then((res) => {
      let { apps } = res;
      Message.getFydeosInfo((err, info) => {
        // remove android apps if not fydeos release version
        // if (info && info.fydeos === false) {
        //   apps = apps.filter(x => !x.downloadUrl.includes(".apk"));
        // }

        dispatch(fetchAppListSuccess(timestamp, apps));
      })
    }).catch((err) => {
      dispatch(fetchAppListFailure(err));
    });
  }
);

export const fetchAppDetailStarted = appId => ({
  type: FETCH_APP_DETAIL_STARTED,
  payload: {
    appId,
  },
});

export const fetchAppDetailSuccess = (appId, data) => ({
  type: FETCH_APP_DETAIL_SUCCESS,
  payload: {
    appId,
    data,
  },
});

export const fetchAppDetailFailure = (appId, err) => ({
  type: FETCH_APP_DETAIL_FAILURE,
  payload: {
    appId,
    err,
  },
});

export const fetchAppDetail = (appId, locale = "en") => (
  (dispatch) => {
    dispatch(fetchAppDetailStarted(appId));
    return api.appDetail(appId, locale).then((res) => {
      dispatch(fetchAppDetailSuccess(appId, res));
    }).catch((err) => {
      dispatch(fetchAppDetailFailure(appId, err));
    });
  }
);

export const handleAppSelected = appId => ({
  type: APP_SELECTED,
  payload: {
    appId,
  },
});

export const setAppState = (appId, appState) => ({
  type: SET_APP_STATE,
  payload: {
    appId,
    appState,
  },
});

export const fetchInstallProgress = (appId, progress, downloadId) => ({
  type: FETCH_INSTALL_PROGRESS,
  payload: {
    appId,
    progress,
    downloadId
  },
});
