/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Message from '../../Message';
import i18n from '../../i18n';
import { setUpdateState } from '../actions';
import { TYPE_ANDROID } from '../constants';

const styles = theme => ({
  button: {
    marginBottom: theme.spacing.unit,
  },
  progress: {
    marginLeft: theme.spacing.unit,
  },
});

const UPDATE_STATE = {
  toUpdate: 0,
  updating: 1,
  didUpdate: 2,
  updateFailed: 3,
};

const UPDATE_STATE_TEXT = [
  'updateButtonTextToUpdate',
  'updateButtonTextToUpdating',
  'updateButtonTextDidUpdate',
  'updateButtonTextUpdateFailed',
];

class UpdateButton extends Component {
  constructor(...args) {
    super(...args);
    this.handleClick = this.handleClick.bind(this);
    this.removeAndInstallApp = this.removeAndInstallApp.bind(this);
    this.openApp = this.openApp.bind(this);
    this.switchUpdateState = this.switchUpdateState.bind(this);
  }

  componentDidMount() {
    if (!Message.connStatus()) {
      this.switchUpdateState(UPDATE_STATE.updateFailed);
    } else {
      this.switchUpdateState(UPDATE_STATE.toUpdate);
    }
  }

  switchUpdateState(appState) {
    const { appId, switchUpdateState } = this.props;
    switchUpdateState(appId, appState);
  }

  handleClick() {
    const { updateState, detail } = this.props;
    const { type } = detail;
    switch (updateState) {
      case UPDATE_STATE.toUpdate:
        if (type.includes(TYPE_ANDROID)) {
          this.updateArc();
        } else {
          this.removeAndInstallApp();
        }
        break;
      case UPDATE_STATE.didUpdate:
        this.openApp();
        break;
      default:
        break;
    }
  }

  openApp() {
    const { appId } = this.props;
    Message.startApp(appId, (err, result) => {
      if (err) console.error(err);
      console.debug(result);
    });
  }

  removeAndInstallApp() {
    const { detail } = this.props;
    const { appIdGoogle } = detail;
    this.switchUpdateState(UPDATE_STATE.updating);

    Message.uninstallApp(appIdGoogle, (uninstallError) => {
      if (uninstallError) {
        this.switchUpdateState(UPDATE_STATE.toUpdate);
        return;
      }

      // [TEMP FIX] replace __MSG_appName__ with name
      const manifest = JSON.parse(detail.manifest);
      manifest.name = detail.name;
      detail.manifest = JSON.stringify(manifest);

      Message.installCrx(detail, (installError) => {
        if (installError) {
          console.error(installError);
          if (installError.message.includes('already installed')) {
            this.switchUpdateState(UPDATE_STATE.didUpdate);
            return;
          }
          this.switchUpdateState(UPDATE_STATE.updateFailed);
        } else {
          this.switchUpdateState(UPDATE_STATE.didUpdate);
        }
      });
    });
  }

  updateArc() {
    const { detail } = this.props;
    // Do not show progress on **install** button
    // if check updates modal is closed
    detail.noProgress = true;
    this.switchUpdateState(UPDATE_STATE.updating);

    Message.installCrx(detail, (installError) => {
      if (installError) {
        console.error(installError);
        this.switchUpdateState(UPDATE_STATE.updateFailed);
      } else {
        this.switchUpdateState(UPDATE_STATE.didUpdate);
      }
    });
  }

  render() {
    const {
      classes,
      intl,
      updateState,
    } = this.props;

    const disabled = updateState === UPDATE_STATE.updateFailed
      || updateState === UPDATE_STATE.updating;

    let updateProgress;
    if (updateState === UPDATE_STATE.updating) {
      updateProgress = (
        <CircularProgress
          className={classes.progress}
          size={14}
          thickness={6.0}
          color="inherit"
        />
      );
    }

    return (
      <Button
        color="primary"
        variant="contained"
        disabled={disabled}
        className={classes.button}
        onClick={this.handleClick}
      >
        {i18n(intl, UPDATE_STATE_TEXT[updateState])}
        {updateProgress}
      </Button>
    );
  }
}

UpdateButton.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  appId: PropTypes.string.isRequired,
  detail: PropTypes.shape({
    appId: PropTypes.string.isRequired,
    manifest: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    type: PropTypes.array.isRequired,
  }),
  updateState: PropTypes.oneOf(Object.values(UPDATE_STATE)),
  switchUpdateState: PropTypes.func.isRequired,
};

UpdateButton.defaultProps = {
  classes: {},
  detail: null,
  updateState: UPDATE_STATE.toUpdate,
};

const mapStateToProps = (state, props) => {
  const { appId } = props;
  const { updateStates } = state.updater;
  const updateState = updateStates[appId];
  return {
    updateState,
  };
};

const mapDispatchToProps = dispatch => ({
  switchUpdateState: (appId, updateState) => {
    dispatch(setUpdateState(appId, updateState));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(injectIntl(UpdateButton)),
);
