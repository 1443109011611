import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import UpdateButton from './UpdateButton';

const styles = () => ({
  gridList: {
    display: 'flex',
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    borderRadius: 0,
    width: 48,
    height: 48,
  },
  title: {

  },
});

const AppUpdater = (props) => {
  const {
    appList,
    classes,
  } = props;

  return (
    <Fragment>
      <GridList
        cellHeight="auto"
        cols={1}
        spacing={10}
        className={classes.gridList}
      >
        {
          appList.map(app => (
            <GridListTile key={app.appId}>
              <Grid
                container
                spacing={16}
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>
                  <Avatar src={app.icon} className={classes.icon} />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="subtitle2" className={classes.title} noWrap>
                    {app.name}
                  </Typography>
                  <Typography variant="caption">
                    {app.version}
                  </Typography>
                </Grid>
                <Grid item>
                  <UpdateButton appId={app.appId} detail={app} />
                </Grid>
              </Grid>
            </GridListTile>
          ))
        }
      </GridList>
    </Fragment>
  );
};

AppUpdater.propTypes = {
  classes: PropTypes.shape({
    gridList: PropTypes.string,
    card: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    button: PropTypes.string,
  }),
  appList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

AppUpdater.defaultProps = {
  classes: {},
};

export default connect()(
  withStyles(styles)(injectIntl(AppUpdater)),
);
