import React, { useState, useEffect } from "react";
import Lottie from "react-lottie-player";

import * as animationData from "./animation_data.json";

const Loading = (props) => {
    const defaultOptions = {
        autoplay: false,
        animationData: animationData
    };

    const [segments, setSegments] = useState([0, 1]);

    useEffect(() => {
        setSegments([0, 0]);
    }, []);

    useEffect(() => {
        if (props.segments) {
            setSegments(props.segments);
        }
    }, [props.segments]);

    return <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Lottie
            animationData={animationData}
            style={{
                width: props.width || 120,
                height: props.height || 120
            }}
            segments={segments}
            play
            loop={true}
        />
    </div>
}

export default Loading;
