import { combineReducers } from 'redux';
import {
  FETCH_APP_LIST_HAS_UPDATES_STARTED,
  FETCH_APP_LIST_HAS_UPDATES_SUCCESS,
  FETCH_APP_LIST_HAS_UPDATES_FAILURE,
  SET_UPDATE_STATE,
} from './actionTypes';

const actionIsValid = (state, action) => action.timestamp >= state.timestamp;

const initAppListHasUpdatesState = {
  loading: false,
  timestamp: 0,
  data: [],
};

const appListHasUpdatesReducer = (state = initAppListHasUpdatesState, action) => {
  switch (action.type) {
    case FETCH_APP_LIST_HAS_UPDATES_STARTED: {
      return {
        ...state,
        loading: true,
        timestamp: action.timestamp,
      };
    }
    case FETCH_APP_LIST_HAS_UPDATES_SUCCESS: {
      const { data } = action.payload;
      if (!actionIsValid(state, action)) {
        return state;
      }
      return {
        ...state,
        loading: false,
        data,
      };
    }
    case FETCH_APP_LIST_HAS_UPDATES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

const initUpdateStates = {};

const updateStatesReducer = (state = initUpdateStates, action) => {
  switch (action.type) {
    case SET_UPDATE_STATE: {
      const { appId, updateState } = action.payload;
      return {
        ...state,
        [appId]: updateState,
      };
    }
    default:
      return state;
  }
};

const reducer = combineReducers({
  appListHasUpdates: appListHasUpdatesReducer,
  updateStates: updateStatesReducer,
});

export default reducer;
