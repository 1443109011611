import api from '../api';
import {
  FETCH_APP_TYPES_STARTED, FETCH_APP_TYPES_SUCCESS, FETCH_APP_TYPES_FAILURE,
  FETCH_APP_CATEGORIES_STARTED, FETCH_APP_CATEGORIES_SUCCESS, FETCH_APP_CATEGORIES_FAILURE,
  FETCH_APP_SORTBY_STARTED, FETCH_APP_SORTBY_SUCCESS, FETCH_APP_SORTBY_FAILURE,
  FETCH_APP_ORDERS_STARTED, FETCH_APP_ORDERS_SUCCESS, FETCH_APP_ORDERS_FAILURE,
  APP_FILTER_CHANGE,
} from './actionTypes';

import Message from '../Message';

export const fetchAppTypesStarted = () => ({
  type: FETCH_APP_TYPES_STARTED,
});

export const fetchAppTypesSuccess = data => ({
  type: FETCH_APP_TYPES_SUCCESS,
  payload: {
    data,
  },
});

export const fetchAppTypesFailure = err => ({
  type: FETCH_APP_TYPES_FAILURE,
  payload: {
    err,
  },
});

export const fetchAppTypes = () => (
  (dispatch) => {
    dispatch(fetchAppTypesStarted());
    return api.types().then((res) => {
      Message.getFydeosInfo((err, info) => {
        // remove android type if not fydeos release version
        if (info && info.fydeos === false) {
          delete res["android"];
        }

        dispatch(fetchAppTypesSuccess(res));
      })
    }).catch((err) => {
      dispatch(fetchAppTypesFailure(err));
    });
  }
);

export const fetchAppCategoriesStarted = () => ({
  type: FETCH_APP_CATEGORIES_STARTED,
});

export const fetchAppCategoriesSuccess = data => ({
  type: FETCH_APP_CATEGORIES_SUCCESS,
  payload: {
    data,
  },
});

export const fetchAppCategoriesFailure = err => ({
  type: FETCH_APP_CATEGORIES_FAILURE,
  payload: {
    err,
  },
});

export const fetchAppCategories = () => (
  (dispatch) => {
    dispatch(fetchAppCategoriesStarted());
    return api.categories().then((res) => {
      dispatch(fetchAppCategoriesSuccess(res));
    }).catch((err) => {
      dispatch(fetchAppCategoriesFailure(err));
    });
  }
);

export const fetchAppSortByStarted = () => ({
  type: FETCH_APP_SORTBY_STARTED,
});

export const fetchAppSortBySuccess = data => ({
  type: FETCH_APP_SORTBY_SUCCESS,
  payload: {
    data,
  },
});

export const fetchAppSortByFailure = err => ({
  type: FETCH_APP_SORTBY_FAILURE,
  payload: {
    err,
  },
});

export const fetchAppSortBy = () => (
  (dispatch) => {
    dispatch(fetchAppSortByStarted());
    return api.sortBy().then((res) => {
      dispatch(fetchAppSortBySuccess(res));
    }).catch((err) => {
      dispatch(fetchAppSortByFailure(err));
    });
  }
);

export const fetchAppOrdersStarted = () => ({
  type: FETCH_APP_ORDERS_STARTED,
});

export const fetchAppOrdersSuccess = data => ({
  type: FETCH_APP_ORDERS_SUCCESS,
  payload: {
    data,
  },
});

export const fetchAppOrdersFailure = err => ({
  type: FETCH_APP_ORDERS_FAILURE,
  payload: {
    err,
  },
});

export const fetchAppOrders = () => (
  (dispatch) => {
    dispatch(fetchAppOrdersStarted());
    return api.orders().then((res) => {
      dispatch(fetchAppOrdersSuccess(res));
    }).catch((err) => {
      dispatch(fetchAppOrdersFailure(err));
    });
  }
);

export const handleFilterChange = filter => ({
  type: APP_FILTER_CHANGE,
  payload: {
    filter,
  },
});
