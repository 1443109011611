export const FETCH_APP_LIST_STARTED = 'FETCH/APP/LIST/STARTED';
export const FETCH_APP_LIST_SUCCESS = 'FETCH/APP/LIST/SUCCESS';
export const FETCH_APP_LIST_FAILURE = 'FETCH/APP/LIST/FAILURE';

export const FETCH_APP_DETAIL_STARTED = 'FETCH/APP/DETAIL/STARTED';
export const FETCH_APP_DETAIL_SUCCESS = 'FETCH/APP/DETAIL/SUCCESS';
export const FETCH_APP_DETAIL_FAILURE = 'FETCH/APP/DETAIL/FAILURE';

export const APP_SELECTED = 'APP/SELECTED';

export const SET_APP_STATE = 'SET/APP/STATE';

export const FETCH_INSTALL_PROGRESS = 'FETCH/INSTALL/PROGRESS';
