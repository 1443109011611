import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  GridList,
  GridListTile,
  Modal,
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  CardActionArea,
  CircularProgress
} from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import { injectIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import AppCard from "./AppCard";
import { handleAppSelected, fetchAppDetail, fetchAppList } from "./actions";
import {
  TYPE_MINI_ANDROID,
  APP_INSTALLER,
  APP_OPENGAPPS,
  APP_RDP,
  APP_ONEDRIVE
} from "./constants";
import utils from "./utils";
import i18n from "../i18n";
import Recommend from "./recommend";
import InstallButton from "./AppCard/InstallButton";

import Loading from "../components/Loading/loading";
import {
  CATEGORY_BYFYDEOS,
  CATEGORY_ANDROID,
  TYPE_BYFYDEOS,
  TYPE_ANDROID
} from "../Sidebar/constants";

const getModalStyle = (top, left) => ({
  top: `${top}%`,
  left: `${left}%`,
  transform: `translate(-${left}%, -${top}%)`
});

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginBottom: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    backgroundColor: theme.palette.background.baseWhite,
    "@media (prefers-color-scheme: dark)": {
      backgroundColor: "rgba(32, 33, 36, 1)"
    }
  },
  gridList: {
    width: "100%",
    paddingTop: "4px"
  },
  card: {
    maxWidth: 345,
    overflow: "visible",
    "@media (prefers-color-scheme: dark)": {
      backgroundColor: "rgba(32, 33, 36, 1)!important"
    }
  },
  media: {
    height: 0,
    paddingTop: "63.63%", // 140:220
    backgroundSize: "cover",
    borderRadius: "12px",
    position: "relative",
    boxShadow:
      "0px 2px 8px 0px rgba(0, 0, 0, 0.06), 0px 2px 2px 0px rgba(0, 0, 0, 0.06)"
  },
  subTitleContent: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden"
  },
  content: {
    paddingTop: 10,
    paddingBottom: theme.spacing.unit,
    paddingLeft: 0,
    paddingRight: theme.spacing.unit / 2
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)"
  },
  androidCard: {
    display: "flex",
    padding: 16,
    borderRadius: 8,
    "&:hover": {
      background: "#F2F2F2",
      cursor: "pointer",
      "@media (prefers-color-scheme: dark)": {
        background: "rgba(63, 64, 66, 1)"
      }
    },
    "@media (prefers-color-scheme: dark)": {
      background: "#202124"
    }
  },
  androidContent: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    padding: "0 0 0 12px",
    "&:last-child": {
      paddingBottom: 0
    }
  },
  androidIcon: {
    width: 46,
    height: 46,
    boxShadow:
      "0px 2px 8px 0px rgba(0, 0, 0, 0.06), 0px 2px 2px 0px rgba(0, 0, 0, 0.06)",
    borderRadius: "12px"
  },
  androidAppName: {
    fontSize: "14px",
    lineHeight: "22px",
    color: "#000000",
    "@media (prefers-color-scheme: dark)": {
      color: "rgba(232, 234, 237, 1)"
    }
  },
  androidAppSubtitle: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#999999"
  },
  androidAction: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto"
  },
  androidInstallButton: {
    width: 56,
    height: 24,
    padding: "1px 14px",
    border: "1px solid #D9D9D9",
    borderRadius: 99,
    background: "#FFFFFF",
    "&:hover": {
      background: "#FFFFFF"
    }
  },
  paper: {
    position: "absolute",
    maxWidth: theme.spacing.unit * 120,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "24px",
    display: "flex",
    alignItems: "center",
    outline: "none",
    borderRadius: 8,
    width: "600px",
    height: "400px",
    "@media (min-width: 1280px)": {
      width: "800px",
      height: "500px"
    },
    "@media (prefers-color-scheme: dark)": {
      background: "#202124"
    }
  },
  paperSmall: {
    position: "absolute",
    width: "50%",
    maxWidth: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  appName: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#000",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    whiteSpace: "nowrap",
    lineHeight: "22px",
    marginBottom: 0,
    "@media (prefers-color-scheme: dark)": {
      color: "#E8EAED"
    }
  },
  navigator: {
    width: theme.spacing.unit * 8,
    height: theme.spacing.unit * 8,
    color: theme.palette.background.paper,
    cursor: "pointer",
    position: "absolute"
  },
  prev: {
    left: -theme.spacing.unit * 8
  },
  next: {
    right: -theme.spacing.unit * 8
  },
  disabled: {
    cursor: "default",
    visibility: "hidden"
  },
  loading: {
    flexGrow: 1,
    margin: theme.spacing.unit * 6,
    textAlign: "center"
  },
  avatarContainer: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      cursor: "pointer"
    }
  },
  avatar: {
    width: 80,
    height: 80,
    margin: theme.spacing.unit
  },
  updateModalTitle: {
    marginBottom: theme.spacing.unit * 2,
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  empty: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "80%"
  },
  emptyText: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#666",
    marginTop: "32px"
  },
  cardItem: {
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 0.85
    }
  },
  overrided: {
    overflow: "visible !important"
  },
  madeByFydeOSBlock: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "16px"
  },
  madeByFydeOSBlockLine: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px"
  },
  block: {
    flexBasis: "calc(50% - 8px)",
    marginRight: "16px",
    borderRadius: "12px",
    "& img": {
      width: "100%",
      opacity: 0,
      visibility: "hidden"
    },
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: "0.85"
    }
  },
  installer: {
    backgroundImage: "url(/images/madeByFydeOS/installer.svg)"
  },
  opengapps: {
    backgroundImage: "url(/images/madeByFydeOS/opengapps.svg)",
    marginRight: "0 !important"
  },
  rdp: {
    flexBasis: "calc(65% - 8px)",
    backgroundImage: "url(/images/madeByFydeOS/rdp.svg)"
  },
  onedrive: {
    flexBasis: "calc(35% - 8px)",
    backgroundImage: "url(/images/madeByFydeOS/onedrive.svg)",
    marginRight: "0 !important"
  },
  blockTitle: {
    position: "absolute",
    top: "16px",
    left: "24px",
    fontSize: "14px",
    fontWeight: "600"
  }
});

const recommendApps = location.host.includes("fydeos.io")
  ? window.recommend.en
  : window.recommend.other;

class Main extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      open: false
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePrevSelected = this.handlePrevSelected.bind(this);
    this.handleNextSelected = this.handleNextSelected.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleUrlParamsAppId = this.handleUrlParamsAppId.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    this.handleUrlParamsAppId();
  }

  getGridListCols() {
    const { width, filter } = this.props;
    if (filter.categoryType === TYPE_ANDROID) {
      if (isWidthUp("lg", width)) {
        return 3;
      }
      if (isWidthUp("md", width)) {
        return 2;
      }
    } else {
      if (isWidthUp("md", width)) {
        return 4;
      }
      if (isWidthUp("sm", width)) {
        return 2;
      }
    }
    return 1;
  }

  handleUrlParamsAppId() {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("appid")) return;
    const appId = urlParams.get("appid");
    setTimeout(() => {
      this.handleOpen(appId);
    }, 1500);
  }

  fetchData(scrollPage) {
    const {
      onLoad,
      filter,
      page,
      loading,
      categories,
      intl: { locale }
    } = this.props;
    if (!filter.category) {
      filter.category = 1;
    }
    // InfiniteScroll component will trigger twice, maybe a bug
    // https://github.com/CassetteRocks/react-infinite-scroller/issues/143
    if (!(loading && scrollPage >= page)) {
      onLoad(
        { ...filter },
        page
      );
    }
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  handleOpen(appId) {
    this.setState({
      open: true
    });
    const { onAppSelected } = this.props;
    onAppSelected(appId);
  }

  handlePrevSelected() {
    const { list, selected } = this.props;
    const idx = list.findIndex(item => item.appId === selected);
    const { onAppSelected } = this.props;
    if (idx > 0) {
      onAppSelected(list[idx - 1].appId);
    }
  }

  handleNextSelected() {
    const { list, selected } = this.props;
    const idx = list.findIndex(item => item.appId === selected);
    const { onAppSelected } = this.props;
    if (idx !== -1 && idx < list.length - 1) {
      onAppSelected(list[idx + 1].appId);
    }
  }

  render() {
    const {
      classes,
      list,
      selected,
      hasMore,
      loading,
      intl,
      filter,
      onAppSelected,
      categories
    } = this.props;
    const { open } = this.state;
    const noApps = !list || !list.length;

    if (noApps && loading) {
      return (
        <div className={classes.root} style={{ height: "100%" }}>
          <div className={classes.loading}>
            <Loading />
          </div>
        </div>
      );
    }
    if (noApps) {
      return (
        <div className={classes.empty}>
          <img src="/images/empty.svg" />
          <div className={classes.emptyText}>{i18n(intl, "noAppsFind")}</div>
        </div>
      );
    }

    const cols = this.getGridListCols();
    let mainContent;
    utils.i18nAppName(list, intl.locale);
    utils.i18nAppSubtitle(list, intl.locale);
    if (filter.categoryType === TYPE_BYFYDEOS) {
      const installer = list.find(x => x.extensionId === APP_INSTALLER);
      const opengapps = list.find(x => x.extensionId === APP_OPENGAPPS);
      const rdp = list.find(x => x.extensionId === APP_RDP);
      const onedrive = list.find(x => x.extensionId === APP_ONEDRIVE);

      mainContent = (
        <div className={classes.madeByFydeOSBlock}>
          <div className={classes.madeByFydeOSBlockLine}>
            <div
              className={classNames(classes.installer, classes.block)}
              onClick={() => this.handleOpen(installer.id)}
            >
              <div className={classes.blockTitle}>
                {installer && installer.title}
              </div>
              <img src="/images/madeByFydeOS/installer.svg" />
            </div>
            <div
              className={classNames(classes.opengapps, classes.block)}
              onClick={() => this.handleOpen(opengapps.id)}
            >
              <div className={classes.blockTitle}>
                {opengapps && opengapps.title}
              </div>
              <img src="/images/madeByFydeOS/opengapps.svg" />
            </div>
          </div>
          <div className={classes.madeByFydeOSBlockLine}>
            <div
              className={classNames(classes.rdp, classes.block)}
              onClick={() => this.handleOpen(rdp.id)}
            >
              <div className={classes.blockTitle}>{rdp && rdp.title}</div>
              <img src="/images/madeByFydeOS/rdp.svg" />
            </div>
            <div
              className={classNames(classes.onedrive, classes.block)}
              onClick={() => this.handleOpen(onedrive.id)}
            >
              <div className={classes.blockTitle}>
                {onedrive && onedrive.title}
              </div>
              <img src="/images/madeByFydeOS/onedrive.svg" />
            </div>
          </div>
        </div>
      );
    } else if (filter.categoryType === TYPE_ANDROID) {
      // 安卓程序的列表
      mainContent = (
        <Fragment>
          <div className={classes.root}>
            <GridList
              cellHeight="auto"
              cols={cols}
              spacing={16}
              className={classes.gridList}
              component={InfiniteScroll}
              pageStart={1}
              hasMore={hasMore}
              loadMore={this.fetchData}
              threshold={400}
              loader={
                <GridListTile key="loader" className={classes.loading}>
                  <Loading width={40} height={40} />
                </GridListTile>
              }
            >
              {list.map(item => (
                <GridListTile key={item.id} className={classes.androidTile}>
                  <Card
                    className={classes.androidCard}
                    square
                    elevation={0}
                    onClick={() => {
                      this.handleOpen(item.id);
                    }}
                    title={item.subtitle}
                  >
                    <CardMedia
                      className={classes.androidIcon}
                      image={item.iconUrl}
                      title={item.title}
                    />
                    <CardContent className={classes.androidContent}>
                      <Typography className={classes.androidAppName}>
                        {item.title}
                      </Typography>
                      <Typography className={classes.androidAppSubtitle}>
                        {item.summary}
                      </Typography>
                    </CardContent>
                    <div className={classes.androidAction}>
                      <InstallButton appId={item.generatedId} detail={item} isAndroid isMini />
                    </div>
                  </Card>
                </GridListTile>
              ))}
            </GridList>
          </div>
        </Fragment>
      );
    } else {
      mainContent = (
        <Fragment>
          <div className={classes.root}>
            <GridList
              cellHeight="auto"
              cols={cols}
              spacing={16}
              className={classes.gridList}
              component={InfiniteScroll}
              pageStart={1}
              hasMore={hasMore}
              loadMore={this.fetchData}
              threshold={400}
              loader={
                <GridListTile key="loader" className={classes.loading}>
                  <Loading width={40} height={40} />
                </GridListTile>
              }
            >
              {list.map(item => (
                <GridListTile
                  key={item.appId}
                  classes={{ tile: classes.overrided }}
                >
                  <Card className={classes.card} square elevation={0}>
                    <div
                      className={classes.cardItem}
                      onClick={() => {
                        this.handleOpen(item.id);
                      }}
                    >
                      <CardMedia
                        className={classes.media}
                        image={item.coverUrl ? item.coverUrl : item.iconUrl}
                        title={item.title}
                      />
                      <CardContent className={classes.content}>
                        <Typography
                          gutterBottom
                          variant="body2"
                          className={classes.appName}
                        >
                          {item.title}
                        </Typography>
                      </CardContent>
                    </div>
                  </Card>
                </GridListTile>
              ))}
            </GridList>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {// 必备之选的 banner, 暂时写死仨
        filter.type === 4 && (
          <>
            <Recommend
              dataSources={recommendApps}
              onAppSelected={this.handleOpen}
            />
            <div className="type_title">{i18n(intl, "essentials")}</div>
          </>
        )}
        {mainContent}
        <Modal
          open={open}
          onClose={this.handleClose}
          style={{ overflowY: "scroll" }}
        >
          <div style={getModalStyle(30, 50)} className={classes.paper}>
            <AppCard />
          </div>
        </Modal>
      </Fragment>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    gridList: PropTypes.string,
    card: PropTypes.string,
    media: PropTypes.string,
    content: PropTypes.string,
    paper: PropTypes.string
  }).isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.string,
  onAppSelected: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    type: PropTypes.number,
    category: PropTypes.string,
    sortBy: PropTypes.string,
    order: PropTypes.string
  }),
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  page: PropTypes.number,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  width: PropTypes.string.isRequired
};

Main.defaultProps = {
  selected: "",
  filter: {
    categories: 22
  },
  hasMore: false,
  loading: false,
  page: 1
};

const mapStateToProps = state => {
  const { appList } = state.app;
  const { filter, categories } = state.basic;  
  return {
    list: appList.data,
    selected: appList.selected,
    hasMore: appList.hasMore,
    loading: appList.loading,
    page: appList.page,
    categories: categories.data,
    filter:
      categories.data.length > 0
        ? {
            ...filter,
          }
        : filter
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: (filter, page) => {
    dispatch(fetchAppList(filter, page));
  },
  onAppSelected: appId => {
    dispatch(handleAppSelected(appId));
    dispatch(fetchAppDetail(appId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(withWidth()(Main))));
