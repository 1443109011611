export const FETCH_APP_TYPES_STARTED = 'FETCH/APP/TYPES/STARTED';
export const FETCH_APP_TYPES_SUCCESS = 'FETCH/APP/TYPES/SUCCESS';
export const FETCH_APP_TYPES_FAILURE = 'FETCH/APP/TYPES/FAILURE';

export const FETCH_APP_CATEGORIES_STARTED = 'FETCH/APP/CATEGORIES/STARTED';
export const FETCH_APP_CATEGORIES_SUCCESS = 'FETCH/APP/CATEGORIES/SUCCESS';
export const FETCH_APP_CATEGORIES_FAILURE = 'FETCH/APP/CATEGORIES/FAILURE';

export const FETCH_APP_SORTBY_STARTED = 'FETCH/APP/SORTBY/STARTED';
export const FETCH_APP_SORTBY_SUCCESS = 'FETCH/APP/SORTBY/SUCCESS';
export const FETCH_APP_SORTBY_FAILURE = 'FETCH/APP/SORTBY/FAILURE';

export const FETCH_APP_ORDERS_STARTED = 'FETCH/APP/ORDERS/STARTED';
export const FETCH_APP_ORDERS_SUCCESS = 'FETCH/APP/ORDERS/SUCCESS';
export const FETCH_APP_ORDERS_FAILURE = 'FETCH/APP/ORDERS/FAILURE';

export const APP_FILTER_CHANGE = 'APP/FILTER/CHANGE';
