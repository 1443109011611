import { combineReducers } from 'redux';
import {
  FETCH_APP_LIST_STARTED, FETCH_APP_LIST_SUCCESS, FETCH_APP_LIST_FAILURE,
  FETCH_APP_DETAIL_STARTED, FETCH_APP_DETAIL_SUCCESS, FETCH_APP_DETAIL_FAILURE,
  APP_SELECTED, SET_APP_STATE,
  FETCH_INSTALL_PROGRESS,
} from './actionTypes';
import { APP_FILTER_CHANGE } from '../Sidebar/actionTypes';
import constants from '../api/constants';

const actionIsValid = (state, action) => action.timestamp >= state.timestamp;

const initAppListState = {
  loading: false,
  selected: '',
  hasMore: false,
  page: 1,
  data: [],
  timestamp: 0,
};

const appListReducer = (state = initAppListState, action) => {
  switch (action.type) {
    case FETCH_APP_LIST_STARTED:
      if (action.page === 1) { // reset page when selecting new type on sidebar
        return {
          ...state,
          loading: true,
          timestamp: action.timestamp,
          page: 1,
        };
      }
      return {
        ...state,
        loading: true,
        timestamp: action.timestamp,
      };
    case FETCH_APP_LIST_SUCCESS: {
      const { data } = action.payload;
      if (!actionIsValid(state, action)) {
        return state;
      }
      const { page } = state;
      return {
        ...state,
        loading: false,
        hasMore: data ? data.length === constants.pageSize : false,
        page: page + 1,
        data: [...state.data, ...(data || [])],
      };
    }
    case FETCH_APP_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case APP_SELECTED: {
      const { appId } = action.payload;
      return {
        ...state,
        selected: appId,
      };
    }
    case APP_FILTER_CHANGE: {
      return {
        ...state,
        data: [],
      };
    }
    default:
      return state;
  }
};

const initAppDetailsState = {};

const appDetailsReducer = (state = initAppDetailsState, action) => {
  switch (action.type) {
    case FETCH_APP_DETAIL_STARTED: {
      const { appId } = action.payload;
      return {
        ...state,
        [appId]: {
          ...state[appId],
          loading: true,
        },
      };
    }
    case FETCH_APP_DETAIL_SUCCESS: {
      const { appId, data } = action.payload;
      return {
        ...state,
        [appId]: {
          ...state[appId],
          loading: false,
          data,
        },
      };
    }
    case FETCH_APP_DETAIL_FAILURE: {
      const { appId } = action.payload;
      return {
        ...state,
        [appId]: {
          ...state[appId],
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

const initAppStates = {};

const appStatesReducer = (state = initAppStates, action) => {
  switch (action.type) {
    case SET_APP_STATE: {
      const { appId, appState } = action.payload;
      return {
        ...state,
        [appId]: appState,
      };
    }
    default:
      return state;
  }
};

const initInstallProgressState = {};

const installProgressReducer = (state = initInstallProgressState, action) => {
  switch (action.type) {
    case FETCH_INSTALL_PROGRESS: {
      const { appId, progress, downloadId } = action.payload;
      return {
        ...state,
        [appId]: {
          ...state[appId],
          progress,
          downloadId
        },
      };
    }
    default:
      return state;
  }
};

const reducer = combineReducers({
  appList: appListReducer,
  appDetails: appDetailsReducer,
  appStates: appStatesReducer,
  installProgress: installProgressReducer,
});

export default reducer;
