import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";
import LinkIcon from "@material-ui/icons/Link";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./slick.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InstallButton from "./InstallButton";
import { STORE_URL } from "./constants";
import utils from "../utils";
import i18n from "../../i18n";
import Loading from "../../components/Loading/loading";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%"
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center"
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
    height: "42px"
  },
  logo: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    width: "42px",
    marginRight: "16px"
  },
  title: {
    display: "flex",
    flexDirection: "column"
  },
  appName: {
    fontWeight: 600,
    color: "#000",
    fontSize: "14px",
    "@media (prefers-color-scheme: dark)": {
      color: "white"
    }
  },
  devName: {
    color: "#999",
    fontSize: "14px"
  },
  linkIcon: {
    marginLeft: theme.spacing.unit,
    cursor: "pointer",
    color: grey[400],
    "&:active": {
      color: "#0b9cda"
    },
    verticalAlign: "middle"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "286px",
    overflowY: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "@media (min-width: 1280px)": {
      maxHeight: "386px"
    }
  },
  overviewText: {
    marginBottom: "8px",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "22px",
    "@media (prefers-color-scheme: dark)": {
      color: "white"
    }
  },
  desc: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  descDetail: {
    wordBreak: "break-word",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#666",
    "@media (prefers-color-scheme: dark)": {
      color: "#999"
    }
  },
  version: {
    marginTop: 0,
    "@media (prefers-color-scheme: dark)": {
      color: "#999"
    }
  },
  media: {
    backgroundSize: "contain",
    height: "290px",
    "@media (max-width: 1279px)": {
      height: "180px"
    }
  },
  lighterText: {
    color: "#666666"
  },
  slides: {
    width: "100%",
    marginRight: theme.spacing.unit * 2,
    marginBottom: "0px",
    position: "relative"
  },
  slideItems: {
    display: "flex",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  slideItem: {
    width: "50%",
    flex: "none",
    marginRight: "16px",
    "@media (min-width: 1280px)": {
      width: "60%"
    }
  },
  slideImg: {
    width: "100%",
    borderRadius: "7px"
  },
  slideBtn: {
    position: "absolute",
    top: "50%",
    transform: "translate(-125%, -25%)",
    transition: "all .2s ease",
    borderRadius: "100%",
    width: "34px",
    height: "34px",
    cursor: "pointer",
    opacity: 0,
    "&.show": {
      opacity: 1
    },
    "&:hover": {
      background: "rgba(0, 0, 0, 0.60)",
      "& svg path": {
        stroke: "#fff"
      }
    }
  }
});

const AppCard = props => {
  const { detail, loading, classes, appId, intl } = props;

  if (loading && !detail) {
    return (
      <div className={classNames(classes.root, classes.loadingContainer)}>
        <Loading width={80} height={80} />
      </div>
    );
  }

  if (!detail || detail.open === false) {
    return (
      <div className={classNames(classes.root, classes.loadingContainer)}>
        {i18n(intl, "getAppInfoError")}
      </div>
    );
  }

  let ScreenshotContainer = null;

  const [showArrow, setShowArrow] = useState(false);

  const settings = {
    showArrows:
      detail.screenshots.length > (detail.type.includes(6) ? 3 : 1)
        ? true
        : false,
    showStatus: false,
    showThumbs: false,
    infiniteLoop: true,
    autoPlay: false,
    interval: 5000,
    emulateTouch: true,
    renderArrowPrev: e => {
      if (detail.screenshots.length <= (detail.type.includes(6) ? 3 : 1))
        return null;

      return (
        <div
          className={classNames(
            "arrow",
            "left",
            !showArrow && "hide",
            detail.type.includes(6) && "android"
          )}
          onClick={e}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <rect
              width="30"
              height="30"
              rx="15"
              transform="matrix(-1.19249e-08 -1 -1 1.19249e-08 30 30)"
              fill="black"
              fillOpacity="0.2"
            />
            <path
              d="M16.4301 18.5738L12.3747 14.5184L16.4301 10.463"
              stroke="white"
              strokeWidth="0.955862"
            />
          </svg>
        </div>
      );
    },
    renderArrowNext: e => {
      if (detail.screenshots.length <= (detail.type.includes(6) ? 3 : 1))
        return null;

      return (
        <div
          className={classNames(
            "arrow",
            "right",
            !showArrow && "hide",
            detail.type.includes(6) && "android"
          )}
          onClick={e}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <rect
              y="30"
              width="30"
              height="30"
              rx="15"
              transform="rotate(-90 0 30)"
              fill="black"
              fillOpacity="0.2"
            />
            <path
              d="M13.5699 18.5738L17.6252 14.5184L13.5699 10.463"
              stroke="white"
              strokeWidth="0.955862"
            />
          </svg>
        </div>
      );
    }
  };

  if (detail.screenshots.length) {
    ScreenshotContainer = (
      <div
        className={classes.slides}
        onMouseEnter={() => setShowArrow(true)}
        onMouseLeave={() => setShowArrow(false)}
      >
        <Carousel {...settings}>
          {detail.type.includes(6)
            ? detail.screenshots
                .map((item, index) => {
                  if (index % 3 !== 0) return null;

                  return (
                    <div
                      key={`${item}-${index}`}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                      }}
                    >
                      <Card
                        key={detail.screenshots[index]}
                        square
                        elevation={0}
                        className={classes.screenshot}
                        style={{
                          width: "24%",
                          marginRight: detail.screenshots[index + 1]
                            ? "16px"
                            : "0"
                        }}
                      >
                        <CardMedia
                          image={detail.screenshots[index]}
                          className={classes.media}
                        />
                      </Card>
                      {detail.screenshots[index + 1] && (
                        <Card
                          key={detail.screenshots[index + 1]}
                          square
                          elevation={0}
                          className={classes.screenshot}
                          style={{
                            width: "24%",
                            marginRight: detail.screenshots[index + 2]
                              ? "16px"
                              : "0"
                          }}
                        >
                          <CardMedia
                            image={detail.screenshots[index + 1]}
                            className={classes.media}
                          />
                        </Card>
                      )}
                      {detail.screenshots[index + 2] && (
                        <Card
                          key={detail.screenshots[index + 2]}
                          square
                          elevation={0}
                          className={classes.screenshot}
                          style={{ width: "24%" }}
                        >
                          <CardMedia
                            image={detail.screenshots[index + 2]}
                            className={classes.media}
                          />
                        </Card>
                      )}
                    </div>
                  );
                })
                .filter(x => x)
            : detail.screenshots.map(item => (
                <Card
                  key={item}
                  square
                  elevation={0}
                  className={classes.screenshot}
                >
                  <CardMedia image={item} className={classes.media} />
                </Card>
              ))}
        </Carousel>
      </div>
    );
  }

  const { title, description } = utils.i18nAppInfo(detail, intl.locale);
  const appUrl = `${STORE_URL}/?appid=${appId}`;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.logo}>
          <img src={detail.iconUrl} className={classes.icon} alt={detail.name} />
          <div className={classes.title}>
            <Typography variant="h6" className={classes.appName}>
              {detail.title}
              <Tooltip
                title={i18n(intl, "copyAppLinkTooltip")}
                placement="right"
              >
                <CopyToClipboard text={appUrl} className={classes.linkIcon}>
                  <LinkIcon style={{ fontSize: 18 }} />
                </CopyToClipboard>
              </Tooltip>
            </Typography>
            <Typography className={classes.devName}>
              {
                detail.author && <span key={detail.author.id} className={classes.lighterText}>
                  {detail.author.name || detail.author.email}
                </span>
              }
            </Typography>
            <Typography className={classes.devName}>
              {detail && detail.type ==='extension' ? detail.extensionId :null}
              </Typography>
          </div>
        </div>
        <InstallButton appId={appId} detail={detail} />
      </div>

      <div className={classes.content}>
        {ScreenshotContainer}
        <div className={classes.desc}>
          <Typography variant="subtitle2" className={classes.overviewText}>
            {i18n(intl, "introductionTitle", "Overview")}
          </Typography>
          <div
            className={classes.descDetail}
            dangerouslySetInnerHTML={{
              __html: description.replace(/\n(\n)?/g, "<br/>")
            }}
          />
          <div className={classes.version}>
            <Typography variant="caption" className={classes.lighterText}>
              {i18n(intl, "versionTitle", "Version: ")}
              {detail.version}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

AppCard.propTypes = {
  appId: PropTypes.string.isRequired,
  detail: PropTypes.shape({
    appId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired
  }),
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    loadingContainer: PropTypes.string,
    progress: PropTypes.string,
    header: PropTypes.string,
    title: PropTypes.string,
    appName: PropTypes.string,
    icon: PropTypes.string,
    desc: PropTypes.string,
    content: PropTypes.string,
    slides: PropTypes.string,
    logo: PropTypes.string
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

AppCard.defaultProps = {
  detail: null,
  loading: false,
  classes: {}
};

const mapStateToProps = state => {
  const { appList, appDetails } = state.app;
  const appId = appList.selected;
  const appDetail = appDetails[appId] || {};
  
  return {
    appId,
    detail: appDetail.data,
    loading: appDetail.loading
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(injectIntl(AppCard))
);
