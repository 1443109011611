import { combineReducers } from 'redux';
import { reducer as basicReducer } from './Sidebar';
import { reducer as appReducer } from './Main';
import { reducer as updaterReducer } from './Updater';

const reducer = combineReducers({
  basic: basicReducer,
  app: appReducer,
  updater: updaterReducer,
});

export default reducer;
