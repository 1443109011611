import config from './config';
import store from '../store';
import { fetchInstallProgress } from '../Main/actions';

let id = 1;
let connStatus = false;
let androidStatus = true;
let chromeVersion = 0;
let init = false;
const messageQue = new Map();
const appListCache = {
  list: null,
  timeStamp: null,
};
let appWindow = null;
let appActive = null;

function isCacheTimeOut() {
  return !appListCache.list || Date.now() - appListCache.timeStamp > 60 * 1000;
}

function setAppListCache(list) {
  appListCache.list = list;
  appListCache.timeStamp = new Date();
}

function createId() {
  id += 1;
  return `store_${id}`;
}

function sendMessage(command, obj = null, cb = null) {
  const msgId = createId();
  const msg = {
    command,
    id: msgId,
    type: config.dir.FROM,
  };
  if (obj) msg.params = obj;
  if (cb) {
    messageQue.set(msgId, cb);
  }
  if (appWindow) {
    appWindow.postMessage(msg, config.storeOrigin);
  }
}

function testConn() {
  const rnd = Math.random();
  sendMessage(config.commands.echo, { rnd }, (err, obj) => {
    if (!err && obj.rnd === rnd) {
      connStatus = true;
    }
  });
}

function testAndroid() {
  sendMessage(config.commands.getFydeosArcStatus, {}, (err, obj) => {
    console.log(obj);
    if (obj && obj.hasApi && !obj.enabled) {
      androidStatus = false;
    }
  });
}

function fetchChromeVersion() {
  sendMessage(config.commands.fetchChromeVersion, {}, (err, obj) => {
    if (!err && obj.version !== undefined) {
      chromeVersion = obj.version;
    }
  });
}

function initMessage(window) {
  if (init) return;
  window.addEventListener(
    'message',
    ({ source, data, origin }) => {
      if (!appWindow && origin === config.storeOrigin) {
        appWindow = source;
        testConn();
        testAndroid();
        fetchChromeVersion();
      }
      if (
        origin !== config.storeOrigin
        || !data.type
        || data.type !== config.dir.TO
      ) {
        return 0;
      }
      const cb = messageQue.get(data.id);
      if (typeof cb === 'function') {
        const res = data.data;
        if (res && res.state) {
          switch (res.state) {
            case config.state.SUCCESS:
              cb(null, res.data || true);
              break;
            case config.state.ERROR:
              cb(new Error(res.error || 'unknown error'), null);
              break;
            default:
              cb(null, res.data || true);
          }
        }
      } else {
        const res = data.data;
        if (res && res.state) {
          switch (res.state) {
            case config.state.PROGRESS:
              store.dispatch(fetchInstallProgress(res.data.appId, res.data.progress, res.data.downloadId));
              break;
            default:
              break;
          }
        }
      }
      messageQue.delete(data.id);
      return 0;
    },
    false,
  );
  init = true;
}

export default {
  getAppList(cb) {
    if (isCacheTimeOut) {
      sendMessage(config.commands.appList, null, (err, list) => {
        if (err) return cb(err);
        setAppListCache(list);
        return cb(null, appListCache.list);
      });
    } else {
      cb(null, appListCache.list);
    }
  },
  getAppListDetail(cb) {
    sendMessage(config.commands.appListDetail, null, (err, list) => {
      if (err) return cb(err);
      return cb(null, list);
    });
  },
  getArcListVersion(arcList, cb) {
    sendMessage(config.commands.arcListVersion, arcList, (err, list) => {
      if (err) return cb(err);
      return cb(null, list);
    });
  },
  connStatus() {
    return connStatus;
  },
  androidStatus() {
    return androidStatus;
  },
  initConnect(window) {
    initMessage(window);
  },
  installCrx(appInfo, cb) {
    sendMessage(config.commands.installCrx, appInfo, cb);
  },
  startApp(appId, cb) {
    sendMessage(config.commands.startApp, { appId }, cb);
  },
  forceStartApp(appId) {
    sendMessage(config.commands.forceStartApp, { appId });
  },
  uninstallApp(appId, cb) {
    sendMessage(config.commands.uninstallApp, { appId }, cb);
  },
  fetchProgress(cb) {
    sendMessage(config.commands.fetchProgress, {}, cb);
  },
  fetchChromeVersion() {
    return chromeVersion;
  },
  openUrl(url) {
    sendMessage(config.commands.openUrl, { url });
  },
  reConnect() {
    testConn();
  },
  switchActiveApp(appId) {
    appActive = appId;
  },
  getArcSignedState(cb) {
    sendMessage(config.commands.getArcSignedState, null, (err, res) => {
      cb(err, res);
    });
  },
  getBoardName(cb) {
    sendMessage(config.commands.getBoardName, {}, (err, obj) => {
      cb(err, obj);
    });
  },
  stopInstallAndroid(downloadId) {
    sendMessage(config.commands.stopInstallAndroid, {
      downloadId
    });
  },
  getFydeosInfo(cb) {
    if (Message.connStatus()) {
      sendMessage(config.commands.getFydeosInfo, {}, (err, obj) => {
        cb(err, obj);
      });
    } else {
      cb(null);
    }
  }
};
