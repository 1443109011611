// Replace `name` with `nameCN`
function i18nAppName(list, locale = 'en') {
  list.forEach((item) => {
    const { nameCN } = item;
    if (!nameCN) return;
    /* eslint-disable no-param-reassign */
    if (locale === 'zh') item.name = nameCN;
  });
}

// Replace `subtitle` with `subtitleCN`
function i18nAppSubtitle(list, locale = 'en') {
  list.forEach((item) => {
    const { subtitleCN } = item;
    if (!subtitleCN) return;
    /* eslint-disable no-param-reassign */
    if (locale === 'zh') item.subtitle = subtitleCN;
  });
}

// Get Chinese or English app info
function i18nAppInfo(detail, locale = 'en') {
  const {
    name, nameCN, description, descriptionCN,
  } = detail;
  if (!descriptionCN || !nameCN) {
    return { name, description };
  }
  if (locale === 'zh') {
    return { name: nameCN, description: descriptionCN };
  }
  return { name, description };
}

export default { i18nAppName, i18nAppSubtitle, i18nAppInfo };
