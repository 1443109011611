import request from './request';
import constants from './constants';

const types = () => (
  request({
    url: '/app/types',
  }).then((res) => {
    if (!res.code) return res;
    return Promise.reject(res);
  })
);

const categories = () => {
  return (
    request({
      url: '/app/categories',
    }).then((res) => {
      return Promise.resolve(res);
    })
  );
}

const sortBy = () => (
  request({
    url: '/app/sortby',
  }).then((res) => {
    if (!res.code) return res;
    return Promise.reject(res);
  })
);

const orders = () => (
  request({
    url: '/app/orders',
  }).then((res) => {
    if (!res.code) return res;
    return Promise.reject(res);
  })
);

const appList = (filter, page = 1, limit = constants.pageSize) => {
  let params = {
    ...filter,
    itemsPerPage: limit,
    page: page,
  };
  const istestingMode = localStorage.getItem("testingMode");
  if (istestingMode === "true") {
    params = {
      ...params,
      testingMode: true
    }
  }
  return request({
    url: '/app/list',
    params
  }).then((res) => {
    return Promise.resolve(res);
  });
}

const appDetail = (appId, locale = "en") => (
  locale = location.host.includes("fydeos.io") ? "en" : "zh",
  request({
    url: `/app/${locale}/${appId}`,
  }).then((res) => {
    return Promise.resolve(res.app);
  })
);

const appListHasUpdates = apps => (
  request({
    url: '/app/updatelist',
    method: 'post',
    data: { apps },
  }).then((res) => {
    if (!res.code) return res;
    return Promise.reject(res);
  })
);

const arcAppIdToPackageName = arcList => (
  request({
    url: '/app/arcpackagename',
    method: 'post',
    data: { arcList },
  }).then((res) => {
    if (!res.code) return res;
    return Promise.reject(res);
  })
);

const storeService = {
  types,
  categories,
  sortBy,
  orders,
  appList,
  appDetail,
  appListHasUpdates,
  arcAppIdToPackageName,
};

export default storeService;
