export const TYPE_NULL = 0;
export const TYPE_APPS = 1;
export const TYPE_EXTENSIONS = 2;
export const TYPE_BYFYDEOS = 3;
export const TYPE_ESSENTIALS = 4;
export const TYPE_GAMES = 5;
export const TYPE_ANDROID = 6;
export const TYPE_EDUCATION = 7;
export const TYPE_THEMES = 8;
export const TYPE_MINI_PROGRAMS = 9;
export const BOARDS_HIDE_MINI_PROGRAMS = ['rpi4-fydeos'];

export const CATEGORY_BYFYDEOS = 22;
export const CATEGORY_ANDROID = 27;
