import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import { waterfall } from 'async-es';
import AppUpdater from './AppUpdater';
import { fetchAppListHasUpdates } from './actions';
import api from '../api';
import i18n from '../i18n';
import Message from '../Message';
import Loading from "../components/Loading/loading";

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${left}%, -${top}%)`,
  };
};

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: '80%',
    minHeight: theme.spacing.unit * 40,
    maxHeight: '80%',
    overflow: 'hidden',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  header: {
    marginBottom: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: grey[500],
  },
  loading: {
    flexGrow: 1,
    margin: theme.spacing.unit * 6,
    textAlign: 'center',
  },
  checkIcon: {
    color: green[500],
  },
  helpIcon: {
    color: orange[500],
    marginLeft: theme.spacing.unit,
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverArea: {
    padding: theme.spacing.unit * 2,
    width: '420px',
  },
});

class Updater extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      open: false,
      anchorEl: null,
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
  }

  fetchData() {
    const { onLoad, loading } = this.props;

    // The following steps are executed one by one
    waterfall([
      function filterArcList(callback) {
        Message.getAppList((err, list) => {
          if (err) {
            callback(err);
          } else {
            const arcList = list.filter(item => item.appType === 'arc_app');
            for (let i = 0; i < arcList.length; i += 1) {
              arcList[i].id = arcList[i].appId;
            }
            callback(null, arcList);
          }
        });
      },
      function requestArcPackageName(arcList, callback) {
        api.arcAppIdToPackageName(arcList).then((res) => {
          callback(null, res);
        }).catch((err) => {
          callback(err);
        });
      },
      function requestArcVersion(arcList, callback) {
        Message.getArcListVersion(arcList, (err, list) => {
          if (err) {
            callback(err);
          } else {
            callback(null, list);
          }
        });
      },
      // Get installed apps and concat arc
      function mergeAppAndArc(arcList, callback) {
        Message.getAppListDetail((err, appList) => {
          if (err) {
            callback(err);
          } else {
            const list = arcList.concat(appList);
            callback(null, list);
          }
        });
      },
    ], (err, list) => {
      if (!loading && list && list.length) {
        onLoad(list);
      }
    });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handlePopoverOpen(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handlePopoverClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { open, anchorEl } = this.state;
    const {
      classes,
      intl,
      appList,
      loading,
    } = this.props;
    const popoverOpen = Boolean(anchorEl);

    let appUpdater;
    const noUpdates = !appList || !appList.length;
    if (loading) {
      appUpdater = (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      );
    } else if (noUpdates) {
      appUpdater = (
        <div className={classes.loading}>
          <CheckCircleIcon className={classes.checkIcon} style={{ fontSize: 36 }} />
          <Typography variant="caption" gutterBottom>
            {i18n(intl, 'noUpdates')}
          </Typography>
        </div>
      );
    } else {
      appUpdater = <AppUpdater appList={appList} />;
    }

    return (
      <Fragment>
        <Modal
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onRendered={this.fetchData}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <div className={classes.header}>
              <Typography variant="h6">
                {i18n(intl, 'checkUpdatesTitle')}
                <HelpIcon
                  className={classes.helpIcon}
                  style={{ fontSize: 16 }}
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={this.handlePopoverOpen}
                  onMouseLeave={this.handlePopoverClose}
                />
              </Typography>
              <Typography variant="subtitle1">
                {i18n(intl, 'checkUpdatesSubtitle')}
              </Typography>
              <IconButton className={classes.closeButton} onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.popoverArea,
                }}
                open={popoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={this.handlePopoverClose}
                disableRestoreFocus
              >
                <Typography variant="caption">
                  {i18n(intl, 'checkUpdatesPopoverText1')}
                </Typography>
                <Typography variant="caption">
                  {i18n(intl, 'checkUpdatesPopoverText2')}
                </Typography>
              </Popover>
            </div>
            {appUpdater}
          </div>
        </Modal>
      </Fragment>
    );
  }
}

Updater.propTypes = {
  classes: PropTypes.shape({
    paper: PropTypes.string,
    header: PropTypes.string,
    closeButton: PropTypes.string,
    loading: PropTypes.string,
    checkIcon: PropTypes.string,
    helpIcon: PropTypes.string,
    popover: PropTypes.string,
    popoverArea: PropTypes.string,
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  appList: PropTypes.arrayOf(PropTypes.object),
  onLoad: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Updater.defaultProps = {
  classes: {},
  appList: [],
  loading: false,
};

const mapStateToProps = (state) => {
  const { appListHasUpdates } = state.updater;
  return {
    appList: appListHasUpdates.data,
    loading: appListHasUpdates.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: (list) => {
    const appListInstalled = [];
    for (let i = 0; i < list.length; i += 1) {
      const { id: appId, version, appType } = list[i];
      appListInstalled.push({ appId, version, appType });
    }
    dispatch(fetchAppListHasUpdates(appListInstalled));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(injectIntl(Updater)),
);
