/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Message from "../../Message";
import i18n from "../../i18n";
import { setAppState, fetchInstallProgress, fetchAppDetail } from "../actions";
import {
  FYDEOS_ANDROID_MINIMUM_VERSION,
  FYDEOS_ANDROID_STATUS_API_MINIMUM_VERSION,
  FYDEOS_REMOTE_DESKTOP_APPID,
  FYDEOS_REMOTE_DESKTOP_WEBID,
  ANDROID_SETTINGS_APPID
} from "./constants";
import utils from "../utils";
import classNames from "classnames";

const styles = theme => ({
  wrapper: {
    textAlign: "right",
    display: "flex",
    alignItems: "center"
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    borderRadius: 45,
    boxShadow: "none",
    textTransform: "none",
    backgroundColor: "white",
    border: "1px solid var(--gray-gray-5, #D9D9D9)",
    zIndex: 2,
    "&:hover": {
      backgroundColor: "white"
    }
  },
  mini: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: "14px",
    lineHeight: 2,
    padding: "0 14px",
    border: "1px solid transparent",
    width: "max-content"
  },
  noInstalled: {
    borderColor: "#D9D9D9",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      borderColor: "#0B9CDA",
      color: "#0B9CDA",
      backgroundColor: "white"
    }
  },
  downloading: {
    borderColor: "#D9D9D9",
    color: "black",
    backgroundColor: "transparent",
    "@media (prefers-color-scheme: dark)": {
      color: "white"
    },
    "&:hover": {
      borderColor: "#D9D9D9",
      color: "black",
      backgroundColor: "transparent",
      "@media (prefers-color-scheme: dark)": {
        color: "white"
      }
    }
  },
  installing: {
    borderColor: "#D9D9D9",
    color: "black",
    backgroundColor: "transparent",
    "@media (prefers-color-scheme: dark)": {
      color: "white"
    },
    "&:hover": {
      borderColor: "#D9D9D9",
      color: "black",
      backgroundColor: "transparent",
      "@media (prefers-color-scheme: dark)": {
        color: "white"
      }
    }
  },
  installed: {
    borderColor: "#0B9CDA",
    color: "white",
    backgroundColor: "#0B9CDA",
    "&:hover": {
      borderColor: "#0B9CDA",
      color: "white",
      backgroundColor: "#0B9CDA"
    }
  },
  progress: {
    marginLeft: theme.spacing.unit
  },
  hint: {
    fontWeight: "bold",
    lineHeight: 1.0,
    color: "grey"
  },
  miniProgressBox: {
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: "45px",
    zIndex: 1,
    height: "100%",
    width: "100%",
    overflow: "hidden"
  },
  miniProgressBlock: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#0B9CDA",
    height: "100%",
    transition: "all 0.2s ease"
  }
});

const APP_STATE = {
  noInstalled: 0,
  installing: 1,
  installed: 2,
  notSupport: 3,
  androidNotActivated: 4,
  downloading: 5
};

const APP_STATE_TITLE = [
  "ADD_TO_FYDEOS",
  "INSTALLING",
  "OPEN_APP",
  "NOT_SUPPORT",
  "ANDROID_NOT_ACTIVATED",
  "DOWNLOADING"
];

async function isAndroidSigned() {
  return new Promise(resolve => {
    Message.getArcSignedState((err, res) => {
      if (err) {
        resolve(true);
        return;
      }
      if (res.value) {
        resolve(true);
        return;
      }
      resolve(false);
    });
  });
}

class InstallButton extends Component {
  constructor(...args) {
    super(...args);
    this.handleClick = this.handleClick.bind(this);
    this.installApp = this.installApp.bind(this);
    this.openApp = this.openApp.bind(this);
    this.activateAndroid = this.activateAndroid.bind(this);
    this.checkInstallState = this.checkInstallState.bind(this);
    this.updateAppState = this.updateAppState.bind(this);

    this.miniProgress = null;

    this.state = {};
  }

  componentDidMount() {
    const { appId } = this.props;
    Message.switchActiveApp(appId);
    if (!Message.connStatus()) {
      this.updateAppState(APP_STATE.notSupport);
    } else {
      this.checkInstallState()
        .then(state => {
          this.updateAppState(state);
        })
        .catch(err => {
          console.error(err);
        });
    }
    const chromiumVersion = Message.fetchChromeVersion();
    this.setState({
      chromiumVersion
    });
  }

  componentDidUpdate(prevProps) {
    const { progress, appId, isMini } = this.props;
    const { chromiumVersion } = this.state;

    if (isMini !== true) {
      return;
    }

    if (prevProps.progress.value === progress.value) {
      return;
    }

    if (
      progress.downloadId != null &&
      progress.downloadId !== prevProps.progress.downloadId
    ) {
      this.setState({
        downloadId: progress.downloadId
      });
    }

    if (progress.value != null && progress.appId) {
      if (progress.appId === appId) {
        this.miniProgress.style = `width: ${progress.value}%`;
        if (
          chromiumVersion != null &&
          typeof chromiumVersion === "number" &&
          // r114 开始可以取消安装
          chromiumVersion >= 114
        ) {
          if (progress.value <= 70) {
            this.updateAppState(APP_STATE.downloading);
          } else {
            this.updateAppState(APP_STATE.installing);
          }
        }
      }
    }
  }

  getAppDetails = () => {
    const { appId, getAppDetail, intl } = this.props;
      const res = getAppDetail(appId, intl.locale);
  };

  updateAppState(appState) {
    const { appId, updateAppState,detail } = this.props;
    if (detail && detail.type === "android" && detail.generatedId) {
       if (appId != detail.generatedId) {
        updateAppState(detail.generatedId, appState);
       }
    }
    updateAppState(appId, appState);
  }

  openApp() {
    const { detail,appId } = this.props;
    const _appId = detail ? detail.extensionId || detail.generatedId ||detail.id : appId;
    if (_appId === FYDEOS_REMOTE_DESKTOP_APPID) {
      Message.forceStartApp(FYDEOS_REMOTE_DESKTOP_WEBID);
      return;
    }
    Message.startApp(_appId, (err, result) => {
      if (err) console.error(err);
      console.debug(result);
    });
  }

  installApp() {
    const { appId, detail, resetInstallProgress, intl} = this.props;
    const { chromiumVersion } = this.state;
    const appState =
      chromiumVersion != null &&
      typeof chromiumVersion === "number" &&
      chromiumVersion >= 114
        ? APP_STATE.downloading
        : APP_STATE.installing;

    this.updateAppState(appState);

    if (!detail) {
      this.getAppDetails();
      const checkTimer = setInterval(() => {
        const { detail } = this.props;
        if (detail) {
          clearInterval(checkTimer);
          this.installApp();
        }
      }, 1000);
      return;
    }

    // Replace __MSG_appName__ in manifest.json
    // to i18n app name in confirmation popup
    if (detail.manifest && detail.manifest !== "") {
      const { title } = detail;
      const manifest = JSON.parse(detail.manifest);
      manifest.name = title;
      detail.manifest = JSON.stringify(manifest);
      detail.appId = detail.extensionId || detail.id;
      detail.isNew = true;
    }

    if(detail.type === "android") {
      detail.arch = "x86_64";
      detail.downloadUrlx86 = detail.downloadUrl;
      detail.appId = detail.generatedId;
    }

    Message.installCrx(detail, err => {
      if (err) {
        console.error(err);
        this.updateAppState(APP_STATE.noInstalled);
      } else {
        this.updateAppState(APP_STATE.installed);
      }
      resetInstallProgress(appId);
    });
  }

  activateAndroid() {
    const { appId } = this.props;
    Message.startApp(ANDROID_SETTINGS_APPID, () => {
      window.location.assign("/?init=android");
    });
  }

  checkInstallState() {
    const { detail, appState, progress, appId } = this.props;
    const _appId = detail ? detail.extensionId || detail.generatedId ||detail.id : appId;
    const { chromiumVersion } = this.state;
    const version = Message.fetchChromeVersion();
    return new Promise(async (resolve, reject) => {
      // Check android support
      if (this.isAndroidApp() && version < FYDEOS_ANDROID_MINIMUM_VERSION) {
        resolve(APP_STATE.notSupport);
        return;
      }
      if (
        this.isAndroidApp() &&
        version >= FYDEOS_ANDROID_STATUS_API_MINIMUM_VERSION
      ) {
        if (!Message.androidStatus()) {
          resolve(APP_STATE.notSupport);
          return;
        }
      }
      // Check android eula is agreed
      if (this.isAndroidApp() && !(await isAndroidSigned())) {
        resolve(APP_STATE.androidNotActivated);
        return;
      }

      const { value } = progress;
      // Installing background
      if (value === 0 && appState === APP_STATE.installing) {
        resolve(APP_STATE.installing);
      }
      // Progress is not null
      if (value) {
        if (
          chromiumVersion != null &&
          typeof chromiumVersion === "number" &&
          // r114 开始可以取消安装
          chromiumVersion >= 114
        ) {
          if (value <= 70) {
            this.updateAppState(APP_STATE.downloading);
          } else {
            this.updateAppState(APP_STATE.installing);
          }
        } else {
          resolve(APP_STATE.installing);
        }
      }

      // Check installed or not
      Message.getAppList((err, list) => {
        if (err) return reject(err);
        for (let i = 0; i < list.length; i += 1) {
          const item = list[i];
          if (item.appId === _appId) {
            return resolve(APP_STATE.installed);
          }
        }
        return resolve(APP_STATE.noInstalled);
      });
    });
  }

  isAndroidApp() {
    const { detail, isAndroid } = this.props;
    return isAndroid === true ? true : detail.type === "android";
  }

  handleClick(e) {
    const {
      appState,
      detail,
      isAndroid,
      isMini,
      resetInstallProgress,
      appId,
    } = this.props;

    let _appId
     _appId = detail ? (detail.extensionId || detail.generatedId) : appId;
    if (isMini) {
      e.stopPropagation();
    }

    const isWebApp =
      isAndroid === true
        ? false
        : detail.type.includes("webapp") && detail.webUrl;
    if (isWebApp) {
      Message.openUrl(detail.webUrl);
      return;
    }

    switch (appState) {
      case APP_STATE.noInstalled:
        this.installApp();
        break;
      case APP_STATE.installed:
        this.openApp();
        break;
      case APP_STATE.androidNotActivated:
        this.activateAndroid();
        break;
      case APP_STATE.downloading:
        if (this.state.downloadId) {
          this.updateAppState(APP_STATE.noInstalled);
          this.setState({
            downloadId: undefined
          });
          resetInstallProgress(_appId);
          if (this.miniProgress) {
            this.miniProgress.style.width = "0%";
          }
          Message.stopInstallAndroid(this.state.downloadId);
        }
        break;
      default:
        break;
    }
  }

  render() {
    const {
      appId,
      classes,
      intl,
      appState,
      progress,
      detail,
      isAndroid,
      isMini
    } = this.props;

    if (appState == null) return null;

    const { value, appId: appIdProgress } = progress;
    const isWebApp =
      isAndroid === true
        ? false
        : detail.type.includes("webapp") && detail.webUrl;

    let installProgress = null;
    if (value > 0 && appId === appIdProgress && isMini !== true) {
      installProgress = (
        <CircularProgress
          className={classes.progress}
          variant="static"
          value={value}
          size={14}
          thickness={6.0}
          color="inherit"
        />
      );
    }

    let activateAndroidHint = null;
    if (appState === APP_STATE.androidNotActivated) {
      activateAndroidHint = (
        <Typography variant="caption" className={classes.hint}>
          {i18n(intl, "activateAndroidHint")}
        </Typography>
      );
    }

    if (isMini === true && appState === APP_STATE.notSupport) {
      return null;
    }

    if (isMini === true && appState === APP_STATE.androidNotActivated) {
      return null;
    }

    return (
      <div className={classes.wrapper}>
        <div style={{ position: "relative" }}>
          <Button
            disabled={!isWebApp && appState === APP_STATE.notSupport}
            variant="outlined"
            className={classNames(
              classes.button,
              isMini === true ? classes.mini : undefined,
              isMini && appState === APP_STATE.installing
                ? classes.installing
                : undefined,
              isMini && appState === APP_STATE.installed
                ? classes.installed
                : undefined,
              isMini && appState === APP_STATE.noInstalled
                ? classes.noInstalled
                : undefined,
              isMini && appState === APP_STATE.downloading
                ? classes.downloading
                : undefined
            )}
            onClick={this.handleClick}
          >
            {isWebApp
              ? i18n(intl, `OPEN_APP${isMini ? "_MINI" : ""}`)
              : i18n(
                  intl,
                  `${APP_STATE_TITLE[appState]}${isMini ? "_MINI" : ""}`
                )}
            {isMini !== true && installProgress}
          </Button>
          {isMini === true && (
            <div className={classes.miniProgressBox}>
              <div
                ref={ref => (this.miniProgress = ref)}
                className={classes.miniProgressBlock}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

InstallButton.propTypes = {
  appId: PropTypes.string || PropTypes.number,
  classes: PropTypes.shape({
    button: PropTypes.string
  }),
  detail: PropTypes.shape({
    appId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  appState: PropTypes.oneOf(Object.values(APP_STATE)),
  updateAppState: PropTypes.func.isRequired,
  resetInstallProgress: PropTypes.func.isRequired,
  progress: PropTypes.shape({
    appId: PropTypes.string,
    value: PropTypes.number
  }),
  getAppDetail: PropTypes.func.isRequired
};

InstallButton.defaultProps = {
  classes: {},
  detail: null,
  appState: undefined,
  progress: {
    appId: null,
    value: 0,
    downloadId: undefined
  }
};

const mapStateToProps = (state, props) => {
  const { appId } = props;
  const { appStates, installProgress, appDetails } = state.app;
  const appState = appStates[appId];
  const appProgress = installProgress[appId];
  if (appProgress) {
    const { progress: value, downloadId } = appProgress;
    if (value) {
      return { appState, appId, progress: { appId, value, downloadId } };
    }
  }

  if (appDetails) {
    const appDetail = appDetails[appId];
    if (appDetail && appDetail.data) {
      return {
        appState,
        detail: appDetail.data
      };
    }
  }

  return { appState };
};

const mapDispatchToProps = dispatch => ({
  updateAppState: (appId, appState) => {
    dispatch(setAppState(appId, appState));
  },
  resetInstallProgress: appId => {
    dispatch(fetchInstallProgress(appId, 0));
  },
  getAppDetail: appId => {
    dispatch(fetchAppDetail(appId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(InstallButton)));
